<template>
  <b-container fluid="md" class="h-100">
    <div ref="myElement" class="h-100">
      <b-row class="bg-white">
        <b-col cols="2">
          <h4 class="pt-50 pb-50">القاعة</h4>
        </b-col>
        <b-col cols="4">
          <h4 class="pt-50 pb-50">النشاط</h4>
        </b-col>
        <b-col cols="3">
          <h4 class="pt-50 pb-50">من الساعة</h4>
        </b-col>
        <b-col cols="3">
          <h4 class="pt-50 pb-50">إلى الساعة</h4>
        </b-col>
      </b-row>
      <b-row v-for="(item , i) in hallOccupancy" class=" row_anami h-row-100" :key="i"
        :style="i % 2 != 0 ? 'background:#EDEDED' : 'background:#fff'" align-h="center">
        <b-col cols="2" class="d-flex align-items-center">
          <h4>{{item.hallOccupancy.hall.code}}</h4>
        </b-col>
        <b-col cols="4" class="d-flex align-items-center">{{ item.hallOccupancy.title }}</b-col>
        <b-col cols="3" class="d-flex align-items-center">
          <h4>{{$moment(item.from_time,['h:mm']).format('hh:mm A')  }}</h4>
        </b-col>
        <b-col cols="3" class="d-flex align-items-center">
          <h4>{{$moment(item.to_time,['h:mm']).format('hh:mm A')  }}</h4>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { BContainer, BRow, BCol, BImg, BCard } from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { mapActions,mapGetters } from "vuex";
import gsap from "gsap";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BImg,
    VuexyLogo, BCard,
  },
  methods: {
    ...mapActions("publicPages/screenTV", ["getHallTv"]),

    animationToRow() {
      document.querySelectorAll(".row_anami").forEach(function (row_anami) {
        gsap.fromTo(".row_anami",{y: -30,opacity:0,scale:0.8}, {
          duration: 2,
          scale: 1,
          opacity: 1,
          delay: 0.3,
          stagger: 0.2,
          y: 0,
          ease: "elastic",
          force3D: true
        });
      });
    },
    animationFromGetApi() {
      const element = this.$refs.myElement;
      gsap.fromTo(element,
        { opacity: 0, scale: 0.7, translateX: "-30%" },
        { opacity: 1, translateX: "0%", scale: 1, duration: 1.2, ease: "expo" }
      );
    },
    animationToGetApi() {
      const element = this.$refs.myElement;
      gsap.fromTo(element,
        { opacity: 1, scale: 1, translateX: "+10%" },
        { opacity: 0, translateX: "0%", scale: 0.5, duration: 1.2, ease: "expo" }
      );
    },
  },
  computed:{
    ...mapGetters("publicPages/screenTV",["hallOccupancy"])
  },
  created(){
    this.getHallTv();
  },

  mounted() {
    this.animationToRow()

    setInterval(async () => {
      await this.animationToGetApi()
      await this.getHallTv();
      await this.animationFromGetApi()
      await this.animationToRow()
    }, 10000)
  }
};

</script>
<style lang="scss">
.h-row-100 {
  height: 14% !important;
}


</style>