<template>
  <b-container fluid="md" style="width: 100vw; height: 100vh;">
    <div class="d-flex justify-content-center align-items-center pt-2 w-100 h-10">
      <vuexy-logo style="width: 75px; height: 75px" />
      <h1 class="mx-2 text-primary h1">منارة حلب القديمة</h1>
    </div>
    <b-row class="mt-2 h-25">
      <b-col cols="2" class="h-100">
        <b-row>
          <b-col>
            <b-card class="h-100">
              <h1 class="text-center">الفعاليات القادمة</h1>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="h-100">
        <b-row>
          <b-col cols="3" v-for="(item, i) in events" :key="i">
            <card-events :events="item"></card-events>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="h-45">
      <b-col cols="5" class="h-100">
        <tabls-halls>
        </tabls-halls>
      </b-col>
      <b-col cols="3" class="h-100">
        <screenshots-halls style="margin-bottom: 10%;" :hall="hallOccupancy[0]"></screenshots-halls>
        <screenshots-halls :hall="hallOccupancy[1]"></screenshots-halls>
      </b-col>
      <b-col cols="4" class="h-100">
        <screen-video></screen-video>
      </b-col>
    </b-row>
    <b-row style="height: 16%;">
      <div class="marquee w-100">
        <div class="d-flex mt-2 w-100 h-60 overflow-x marquee-content">
          <div v-for="(item, id) in QueueTv" :key="id" class=" mx-2" style="width: 240px !important;">
            <q-item class="mx-2" style="width: 240px !important;" :item="item"></q-item>
          </div>
        </div>
      </div>
    </b-row>
  </b-container>
</template>
<script>
import { BContainer, BRow, BCol, BImg, BCard } from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import CardEvents from "../../components/CardEvents.vue";
import TablsHalls from "../../components/TablsHalls.vue";
import ScreenshotsHalls from "../../components/ScreenshotsHalls.vue";
import ScreenVideo from "../../components/ScreenVideo.vue";
import QItem from "@/components/q-item.vue"
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BImg,
    VuexyLogo,
    BCard,
    CardEvents,
    TablsHalls,
    ScreenshotsHalls,
    ScreenVideo,
    QItem
  },
 
  data() {
    return {
      currentPage: 1,
    }
  },
  methods: {
    ...mapActions("publicPages/screenTV", ["getEvent", "getQueueTv"])
  },
  computed: {
    ...mapGetters("publicPages/screenTV", ["events", "hallOccupancy", "QueueTv","loadingQ"])
  },

  created() {
    this.getQueueTv();
    this.getEvent()
    const root = document.documentElement;
    const marqueeElementsDisplayed = getComputedStyle(root).getPropertyValue("--marquee-elements-displayed");
    const marqueeContent = document.querySelector("div.marquee-content");

    root.style.setProperty("--marquee-elements", marqueeContent.children.length);

    for (let i = 0; i < marqueeElementsDisplayed; i++) {
      marqueeContent.appendChild(marqueeContent.children[i].cloneNode(true));
    }

  }
};
</script>

<style lang="scss">
.marquee {
  overflow: hidden;
}

.marquee-content {
  animation: marquee 40s linear infinite;

}



@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}
</style>